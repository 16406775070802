<template>
  <div class="pt-7 pb-7">
    <div class="container mt-2">
      <p class="h3 mb-4 text-capitalize text-dark px-3 text-center">
        {{ Certificate.CertificateNo }}
      </p>

      <div class="row justify-content-end mx-0 mb-3">
        <el-dropdown
          v-if="loaded && $f.statusCertificateOptions(Certificate).length"
          trigger="click"
          :hide-on-click="true"
          @command="(comd) => $f.statusCertificate(Certificate, comd, false, $refs)"
        >
          <el-button type="primary" size="mini">
            <i class="el-icon-arrow-down"></i> {{ $t.translate("BTN_OPTIONS") }}
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <template
                v-for="item in $f.statusCertificateOptions(Certificate)"
                :key="item.command"
              >
                <el-dropdown-item :command="item.command">{{
                  $t.translate(item.label)
                }}</el-dropdown-item>
              </template>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>

      <div class="shadow bg-white p-around table-responsive mb-4">
        <table class="form-table append-table">
          <tr>
            <td class="form-tb-head">
              {{
                Certificate?.Type == "CERTIFICATE"
                  ? $t.translate("LBL_CERT_INFO")
                  : $t.translate("LBL_STATE_INFO")
              }}
            </td>
          </tr>
          <tr class="form-record">
            <td class="sm-no-pb">
              {{
                Certificate?.Type == "CERTIFICATE"
                  ? $t.translate("LBL_CERT_NO")
                  : $t.translate("LBL_STATEMENT_NO")
              }}
            </td>
            <td class="text-md-right">
              <b>{{ Certificate.CertificateNo }}</b>
            </td>
          </tr>

          <tr class="form-record">
            <td class="sm-no-pb">{{ $t.translate("FRM_ASSESSMENT_SERVICE_NAME") }}</td>
            <td class="text-md-right">
              <b>
                {{ $h.formatName(Certificate, "ServiceName") }}
                <span v-if="$h.formatName(Certificate, 'ServiceNameExtra', true)">
                  - {{ $h.formatName(Certificate, "ServiceNameExtra") }}</span
                >
              </b>
            </td>
          </tr>

          <tr class="form-record">
            <td class="sm-no-pb">{{ $t.translate("FRM_TYPE_OF_DELIVERABLE") }}</td>
            <td class="text-md-right">
              <b>{{ $h.mapLabel(Certificate.Type, ServiceTypeOpts) }}</b>
            </td>
          </tr>

          <tr class="form-record">
            <td class="sm-no-pb">{{ $t.translate("LBL_APPLICANT") }}</td>
            <td class="text-md-right">
              <b>{{ $h.formatOrgBranch(Certificate) }}</b>
            </td>
          </tr>

          <tr class="form-record">
            <td class="sm-no-pb">{{ $t.translate("LBL_MANAGEMENT_REPRESENTATIVE") }}</td>
            <td class="text-md-right">
              <b>{{ Certificate?.Representative }}</b>
            </td>
          </tr>

          <!-- <tr class="form-record">
            <td class="sm-no-pb">Issued By:</td>
            <td class="text-md-right">
              <b>{{ $h.getFullName(Certificate?.User) }}</b>
            </td>
          </tr> -->

          <tr class="form-record">
            <td class="sm-no-pb">
              {{
                Certificate?.Type == "CERTIFICATE"
                  ? $t.translate("TTL_CERT_ISSUANCE_DATE")
                  : $t.translate("TTL_STATEMENT_ISSUANCE_DATE")
              }}:
            </td>
            <td class="text-md-right">
              <b>{{ $h.formatDateTime(Certificate?.DateData?.IssuedDate) }}</b>
            </td>
          </tr>

          <tr class="form-record">
            <td class="sm-no-pb">{{ $t.translate("FRM_EXPIRY_DATE") }}:</td>
            <td class="text-md-right">
              <b>{{ $h.formatDateTime(Certificate?.ExpiryDateTime) }}</b>
            </td>
          </tr>

          <tr class="form-record">
            <td class="sm-no-pb">{{ $t.translate("TTL_AMENDMENT_DATE") }}:</td>
            <td class="text-md-right">
              <b>{{ $h.formatDateTime(Certificate?.DateData?.AmendmentDate) }}</b>
            </td>
          </tr>

          <tr>
            <td class="form-tb-head" style="padding-top: 20px">
              {{ $t.translate("LBL_STATUS") }}
            </td>
          </tr>

          <tr class="form-record">
            <td class="sm-no-pb">{{ $t.translate("LBL_STATUS") }}:</td>
            <td class="text-md-right">
              <el-tag
                :type="$configs.certificateStatusStyle[Certificate.CertificateStatusCode]"
                :effect="
                  Certificate.CertificateStatusCode == 'PUBLISHED' ? 'dark' : 'plain'
                "
                disable-transitions
                >{{ $t.translate(Certificate.CertificateStatusName) }}</el-tag
              >
            </td>
          </tr>
        </table>

        <div class="td-px timeline-cont" v-if="!$h.hasRoles(['CLIENT'])">
          <el-collapse>
            <el-collapse-item :title="$t.translate('FRM_STATUS_UPDATE_TIMELINE')">
              <el-timeline class="form-record pt-3 mt-2">
                <el-timeline-item
                  v-for="(submission, index) in Certificate.StatusHistory"
                  :key="index"
                  :timestamp="$h.formatDateTime(submission.LastCreated)"
                >
                  <span v-if="!submission.IsRemark">
                    {{
                      $t.swapValues(
                        {
                          User: $h.getFullName(submission.User, null, null, true),
                          ProcessUser: $h.getFullName(submission?.ProcessUser),
                          From: submission.From,
                          To: submission.To,
                        },
                        $t.translate(submission.RenderStatus)
                      )
                    }}
                  </span>

                  <span v-else>{{
                    $t.swapValues(
                      {
                        User: $h.getFullName(submission.User, null, null, true),
                        ProcessUser: $h.getFullName(submission?.ProcessUser),
                        From: submission.From,
                        To: submission.To,
                      },
                      $t.translate("LBL_REMARK_ADDED_BY_USER")
                    )
                  }}</span>

                  <div v-if="submission.Remark" style="white-space: pre-line">
                    {{ submission.Remark }}
                  </div>
                </el-timeline-item>
              </el-timeline>

              <div
                class="comment-container-flex"
                style="margin-bottom: 0px; padding-left: 30px"
              >
                <div class="comment-avatar">
                  <el-avatar> {{ $h.getInitials(User) }} </el-avatar>
                </div>
                <div class="comment-text-cont" style="padding: 0px; background: none">
                  <el-input
                    :placeholder="$t.translate('FRM_WRITE_A_COMMENT')"
                    :disabled="formData.Submitting"
                    v-model="formData.Comment"
                    :autosize="{ minRows: 0, maxRows: 4 }"
                    type="textarea"
                  />
                  <el-button
                    @click="submitComment"
                    class="mt-3 f-white"
                    type="primary"
                    size="mini"
                  >
                    {{ $t.translate("BTN_SUBMIT") }}
                  </el-button>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>

        <!-- <template v-if="Application.MachformEntryKey">
          <table class="form-table append-table">
            <tr>
              <td class="form-tb-head" style="padding-top: 20px">
                {{ $t.translate("TTL_ASSESSMENT_CHECKLIST") }}
              </td>
            </tr>
          </table>
          <AssessmentChecklist :data="Application" />
        </template> -->
      </div>

      <div class="shadow bg-white p-around table-responsive mb-4">
        <table class="form-table append-table">
          <tr>
            <td class="form-tb-head">{{ $t.translate("LBL_APPLICATIONS") }}</td>
          </tr>
        </table>

        <div class="td-px timeline-cont">
          <Table
            url="/application/list"
            :query="{ ...Filter, IsShowNextCycle: 1 }"
            ref="table"
            :default-sort="{ prop: Filter.SortBy, order: Filter.SortOrder }"
            @sort-change="sortChange"
            :viewData="viewApplication"
            class="gen-table-bd"
          >
            <el-table-column
              sortable="custom"
              :sort-orders="['ascending', 'descending']"
              prop="ApplicationNo"
              :label="$t.translate('LBL_APPLICATION_NO')"
              width="250"
              class-name="pl-1"
            >
              <template #default="scope">
                {{ scope.row.ApplicationNo || "---" }}
                <div
                  style="margin-top: 10px; font-size: 12px; color: red"
                  v-if="
                    scope.row.StartDateTime &&
                    scope.row.ApplicationStatusCode == 'INPROGRESS'
                  "
                >
                  {{ $t.translate("LBL_NEXT_ASSESSMENT_CYCLE_DATE") }}<br />
                  {{ $h.formatDate(scope.row.StartDateTime) }} -
                  {{ $h.formatDate(scope.row.EndDateTime) }}
                </div>
              </template>
            </el-table-column>

            <el-table-column
              sortable="custom"
              :sort-orders="['ascending', 'descending']"
              prop="AssessmentType"
              :label="$t.translate('FRM_ASSESSMENT_TYPE')"
              width="240"
            >
              <template #header>
                <TableFilter
                  :handleSelection="
                    (arr) => $refs.table.currentSelection(arr, 'AssessmentType')
                  "
                  :hasSelection="true"
                  :fieldName="$t.translate('FRM_ASSESSMENT_TYPE')"
                  url="envconf/AccessmentTypeOpts"
                  fieldKey="value"
                  fieldLabel="label"
                />
              </template>

              <template #default="scope">
                {{ $t.translate(scope.row.AssessmentTypeLabel) }}
              </template>
            </el-table-column>

            <el-table-column
              sortable="custom"
              :sort-orders="['ascending', 'descending']"
              prop="LastCreated"
              :label="$t.translate('LBL_CREATED_DATE')"
              width="200"
            >
              <template #default="scope">
                {{ $h.formatDateTime(scope.row.LastCreated) }}
              </template>
            </el-table-column>

            <el-table-column
              sortable="custom"
              :sort-orders="['ascending', 'descending']"
              prop="LastModified"
              :label="$t.translate('LBL_LAST_UPDATE')"
              width="200"
            >
              <template #default="scope">
                {{ $h.formatDateTime(scope.row.LastModified) }}
              </template>
            </el-table-column>

            <el-table-column
              fixed="right"
              :label="$t.translate('LBL_STATUS')"
              width="150"
            >
              <template #header>
                <TableFilter
                  :handleSelection="
                    (arr) => $refs.table.currentSelection(arr, 'ApplicationStatusCode')
                  "
                  :hasSelection="true"
                  :fieldName="$t.translate('LBL_STATUS')"
                  url="envconf/ApplicationStatusOpts"
                  fieldKey="value"
                  fieldLabel="label"
                />
              </template>

              <template #default="scope">
                <el-tag
                  v-if="scope.row.ApplicationStatusCode != 'WAITING'"
                  :type="$configs.applicationStatusStyle[scope.row.ApplicationStatusCode]"
                  effect="dark"
                  disable-transitions
                  >{{ $t.translate(scope.row.ApplicationStatusName) }}</el-tag
                >
                <span v-if="scope.row.ApplicationStatusCode == 'WAITING'">---</span>
              </template>
            </el-table-column>
          </Table>
        </div>
      </div>
    </div>
  </div>

  <ApplicationModal ref="select" />
  <ExportModal :types="['PDF', 'EXCEL']" ref="export" />
</template>

<script>
import { useRoute } from "vue-router";
import { onMounted, onUnmounted, reactive, ref, computed } from "vue";
import { get, post, isValidResponse } from "@/utils/axios";
import { store } from "@/store";
import { router } from "@/utils/router";
import { helpers } from "@/utils/helpers";

import Table from "@/components/Table.vue";
import TableFilter from "@/components/TableFilter.vue";
import ApplicationModal from "@/components/ApplicationModal";
import ExportModal from "@/components/ExportModal";

export default {
  components: {
    Table,
    TableFilter,
    ApplicationModal,
    ExportModal,
  },
  setup() {
    const loaded = ref(false);
    const route = useRoute();
    let User = store.getters["user/getData"]?.UserData;

    let Certificate = ref({});
    let { ServiceTypeOpts } = store.getters["config/getData"]?.envConf;

    let formData = reactive({
      Comment: "",
      Submitting: false,
    });

    let getCertificate = async () => {
      let info = await get("/application/certificate/view", {
        CertificateKey: route.query.key,
        Assessment: 1,
      });
      Certificate.value = info?.Certificate;
    };

    onMounted(async () => {
      await getCertificate();
      loaded.value = true;
    });

    const viewApplication = (row, opt) => {
      if (!row?.IsAbleToEditForm) {
        return helpers.showWarning({
          Code: "LBL_NOT_AVAILABLE_FOR_EDIT",
          Message: "LBL_NOT_AVAILABLE_FOR_EDIT",
        });
      }

      router.changeRoute("/viewform", { key: row.ApplicationKey });
    };

    const submitComment = async () => {
      if (!formData.Comment || formData.Submitting) return;
      formData.Submitting = true;

      let res = await post("/application/certificate/comment", {
        Remark: formData.Comment,
        CertificateKey: route.query.key,
      });

      formData.Submitting = false;

      if (isValidResponse(res)) {
        formData.Comment = null;
        await getCertificate();
      }
    };

    let table = ref("table");

    const sortChange = ({ prop, order }) => {
      Filter.SortBy = prop;
      Filter.SortOrder = order;
      filterAction();
    };

    let filterAction = async () => {
      await table.value.handleCurrentChange(1, Filter);
    };

    let Filter = reactive({
      CertificateKey: route.query.key,
      SortBy: "ApplicationNo",
      SortOrder: "descending",
    });

    return {
      table,
      sortChange,
      filterAction,
      Filter,
      submitComment,
      formData,
      User,
      Certificate,
      viewApplication,
      loaded,
      ServiceTypeOpts,
    };
  },
};
</script>
<style>
.w-follow {
  min-width: 80px;
  text-align: right;
  padding-right: 2px;
}
.status-tag {
  height: unset;
  padding: 3px 10px;
  line-height: unset;
}
.timeline-cont .el-collapse,
.timeline-cont .el-collapse-item__wrap,
.timeline-cont .el-collapse-item__header {
  border-top: 0px;
  border-bottom: 0px;
}
.timeline-cont .el-collapse {
  --el-collapse-header-height: unset;
  padding: 8px 0px;
}
.timeline-cont .el-collapse-item__content {
  padding-bottom: 5px;
}
.timeline-cont .el-collapse * {
  font-size: 0.85rem;
  /* color: black; */
}
.fixed-b-row {
  position: fixed;
  bottom: 0px;
  display: flex;
  width: 100%;
  z-index: 99;
}
.sign-image {
  width: 200px;
  height: 100px;
  border: 1px solid #dfdddd;
  object-fit: contain;
}

@media (min-width: 769px) and (max-width: 1180px) {
  .text-md-right {
    display: flex !important;
    justify-content: flex-end;
  }
  .text-md-right b {
    flex: 1;
  }
}

@media (max-width: 768px) {
  .text-md-right {
    display: flex !important;
  }
  .w-follow {
    min-width: 30px;
    flex: 1;
    text-align: right;
  }
  .nav-bottom .sm-cus-btn {
    min-width: unset;
    padding: 0px 15px;
  }
}
</style>
